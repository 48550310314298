import {
  BETWEEN,
  eflStringToFilterNode,
  type FilterExpression,
  type FilterNode,
  IS,
  OR,
} from '@attest/efl'
import { isRange, type Range } from '@attest/util'

import { fieldAndValues } from './filter'
import { isRoundDemographicArrayValue } from './model'

export function createRoundSurveyIdsFilterExpression(surveyIds: string[]): FilterExpression {
  return IS(...fieldAndValues('survey.id', surveyIds))
}

export function createRoundSurveyIdFilterExpression(surveyId: string): FilterExpression {
  return IS(...fieldAndValues('survey.id', surveyId))
}

export function createRoundSegmentFilterNode(efl: string): FilterNode {
  return eflStringToFilterNode(efl)
}

export function createRoundSuccessfulFilterExpression(): FilterExpression {
  return IS(...fieldAndValues('outcome', ['COMPLETED', 'APPROVED']))
}

export function createRoundCompletedFilterExpression(): FilterExpression {
  return IS(...fieldAndValues('outcome', 'COMPLETED'))
}

export function createRoundApprovedFilterExpression(): FilterExpression {
  return IS(...fieldAndValues('outcome', 'APPROVED'))
}

export function createRoundAnswerIdsFilterExpression(
  cardId: string,
  answersIds: string[],
): FilterExpression {
  return IS(...fieldAndValues(`cards.${cardId}.answers.*.id`, answersIds))
}

export function createRoundIdsFilterExpression(ids: string[]): FilterExpression {
  return IS(...fieldAndValues(`id`, ids))
}

export function createRoundAnswersOrderFilterNode(
  cardId: string,
  answerIds: string[],
  orders: number[],
): FilterNode {
  return OR(
    answerIds.map(answerId =>
      IS(...fieldAndValues(`cards.${cardId}.answers.${answerId}.order`, orders)),
    ),
  )
}

export function createRoundSegmentationsFilterNode(
  name: string,
  filters: Range[] | string[],
): FilterNode {
  if (filters.every(isRange)) {
    return OR(filters.map(filter => createRoundSegmentationFilterNode(name, filter)))
  }

  return IS(
    ...fieldAndValues(
      isRoundDemographicArrayValue(name)
        ? (`demographics.${name}.*` as const)
        : (`demographics.${name}` as const),
      filters,
    ),
  )
}

export function createRoundSegmentationFilterNode(
  name: string,
  filter: Range | string,
): FilterNode {
  if (isRange(filter)) {
    return BETWEEN(...fieldAndValues(`demographics.age`, [filter.min, filter.max]))
  }

  return IS(
    ...fieldAndValues(
      isRoundDemographicArrayValue(name)
        ? (`demographics.${name}.*` as const)
        : (`demographics.${name}` as const),
      filter,
    ),
  )
}

export function createRoundCardIdFilterExpression(cardId: string): FilterExpression {
  return IS(...fieldAndValues(`cards.*.id`, cardId))
}

export function createRoundWaveTimestampFilterExpression(waveTimestamp: number): FilterExpression {
  return IS(...fieldAndValues('waveTimestamp', waveTimestamp))
}

export function createRoundAudienceIdFilterExpression(audienceId: string): FilterExpression {
  return IS(...fieldAndValues('audience.id', audienceId))
}

export function createRoundAudienceCountryFilterExpression(country: string): FilterExpression {
  return IS(...fieldAndValues('audience.country', country))
}
