import { withClient } from './client'
import { DEFAULTS } from './defaults'

export type FeatureSwitchKey = keyof typeof DEFAULTS
export type FeatureSwitchValue<K extends FeatureSwitchKey = FeatureSwitchKey> = (typeof DEFAULTS)[K]
export type FeatureSwitchRecord = { [K in FeatureSwitchKey]: FeatureSwitchValue<K> }

export function getVariationKey<T extends FeatureSwitchKey>(fs: T): T {
  return fs
}

/**
 * Get variation value from client, pass fallback for custom fallback value.
 * Default variation values for when client is unavailable is set in `./defaults.ts`
 *
 * Add `config.json` in root of this package to configure local feature switch values
 *
 * @example
 * ```ts
 * import { getVariation } from '@attest/feature-switch'
 *
 * const value = getVariation('key')
 *
 */
export function getVariation<K extends FeatureSwitchKey>(
  fs: K,
  fallback?: FeatureSwitchValue<K>,
): FeatureSwitchValue<K> {
  const overrideFallback = !('Cypress' in globalThis) ? DEFAULTS[fs] : undefined
  return withClient().variation(fs, fallback ?? overrideFallback ?? DEFAULTS[fs])
}

/**
 * Get all variation values.
 *
 * @example
 * ```ts
 * import { getAllVariations } from '@attest/feature-switch'
 *
 * const variations = getAllVariations()
 *
 */
export function getAllVariations(): typeof DEFAULTS {
  return {
    ...DEFAULTS,
    ...(!('Cypress' in window) ? DEFAULTS : {}),
    ...withClient().allFlags(),
  }
}
