import type { Card, CardGuid } from '@attest/editor-card'
import { type RoutingGraph, traverseByServingOrderToNode } from '@attest/routing-graph'
import { isDefined } from '@attest/util'

import {
  DISPLAY_LOGIC_ANSWER_OPERATOR,
  type DisplayLogicCondition,
  type DisplayLogicOperator,
  type DisplayLogicRule,
} from '.'
import type { DisplayLogicAnswer } from './interface'

export function isDisplayLogicReferenceCard(
  guid: CardGuid,
  guidToDisplayLogicRule: Record<CardGuid, DisplayLogicRule>,
): boolean {
  return Object.values(guidToDisplayLogicRule)
    .flatMap(rule => [...rule.conditions])
    .some(({ referenceGuid }) => referenceGuid === guid)
}

export function isDisplayLogicTargetCard(
  guid: CardGuid,
  guidToDisplayLogicRule: Record<CardGuid, DisplayLogicRule>,
): boolean {
  const length = guidToDisplayLogicRule[guid]?.conditions?.length
  return length !== undefined && length > 0
}

export function getDisplayLogicReferenceCardsForCard(
  graph: RoutingGraph,
  guidToCard: Record<CardGuid, Card>,
  guid: CardGuid,
): Card[] {
  if (graph.order === 1) {
    return []
  }

  return [...traverseByServingOrderToNode(graph, guid)]
    .map(node => guidToCard[node])
    .filter(isDefined)
    .filter(card => card.guid !== guid && isCardSupportedAsDisplayLogicReferenceCard(card))
}

export function getDisplayLogicTargetsForQuestion(
  cardGuid: CardGuid,
  rules: DisplayLogicRule[],
): CardGuid[] {
  return rules
    .flatMap(rule => [...rule.conditions.map(condition => ({ ...condition, guid: rule.guid }))])
    .filter(({ referenceGuid }) => referenceGuid === cardGuid)
    .map(({ guid }) => guid)
}

export function getDisplayLogicTargetsForAnswer(
  targetAnswerId: string,
  rules: DisplayLogicRule[],
): CardGuid[] {
  return rules
    .flatMap(rule => [...rule.conditions.map(condition => ({ ...condition, guid: rule.guid }))])
    .filter(({ answers }) => answers.some(({ answerId }) => answerId === targetAnswerId))
    .map(({ guid }) => guid)
}

export function getDisplayLogicTargetsForSubject(
  targetSubjectId: string,
  rules: DisplayLogicRule[],
): CardGuid[] {
  return rules
    .flatMap(rule => [...rule.conditions.map(condition => ({ ...condition, guid: rule.guid }))])
    .filter(({ answers }) => answers.some(({ subjectId }) => subjectId === targetSubjectId))
    .map(({ guid }) => guid)
}

function isCardSupportedAsDisplayLogicReferenceCard(card: Card) {
  return (
    card.question?.type && ['single_choice', 'multiple_choice', 'grid'].includes(card.question.type)
  )
}

export function assertIsDisplayLogicOperator(value: string): asserts value is DisplayLogicOperator {
  if (['AND', 'OR'].includes(value)) return
  throw new TypeError('Value is not a valid Display Logic operator')
}

export function displayLogicSubjectHeadersMatch(
  displayLogicAnswer: DisplayLogicAnswer,
  subjectId: string,
): boolean {
  if ('subjectId' in displayLogicAnswer) return displayLogicAnswer.subjectId === subjectId
  return false
}

function getAnswersFromIsSelectedConditions(
  conditions: DisplayLogicCondition[],
): DisplayLogicAnswer[] {
  return conditions
    .filter(
      ({ answerOperator }) =>
        answerOperator === DISPLAY_LOGIC_ANSWER_OPERATOR.ALL_SELECTED ||
        answerOperator === DISPLAY_LOGIC_ANSWER_OPERATOR.ANY_SELECTED,
    )
    .flatMap(({ answers }) => answers)
}

function getAnswersFromIsNotSelectedConditions(
  conditions: DisplayLogicCondition[],
): DisplayLogicAnswer[] {
  return conditions
    .filter(({ answerOperator }) => answerOperator === DISPLAY_LOGIC_ANSWER_OPERATOR.NONE_SELECTED)
    .flatMap(({ answers }) => answers)
}

export function areAnswersRequiredAsSelectedAndNotSelected(
  conditions: DisplayLogicCondition[],
): boolean {
  return getAnswersFromIsSelectedConditions(conditions).some(answerSelected =>
    getAnswersFromIsNotSelectedConditions(conditions).some(
      answerNotSelected =>
        answerSelected.answerId === answerNotSelected.answerId &&
        answerSelected.subjectId === answerNotSelected.subjectId,
    ),
  )
}

export function hasWarningDisplayLogicQuestionsNotVisibleToAllAudiences(
  conditions: DisplayLogicCondition[],
  audienceIds: string[],
  cardsReachableForAudiences: {
    [audienceId: string]: Set<string>
  },
  cards: { [cardGuid: string]: Card },
): boolean {
  return conditions.some(condition => {
    const sourceCard = cards[condition.referenceGuid]
    if (!sourceCard) {
      return false
    }

    return audienceIds.some(
      audienceId => !cardsReachableForAudiences[audienceId].has(sourceCard.guid),
    )
  })
}

export function hasWarningDisplayLogicAnswersNotVisibleToAllAudiences(
  conditions: DisplayLogicCondition[],
  guidToCard: Record<CardGuid, Card>,
): boolean {
  if (conditions.length === 0) {
    return false
  }
  const surveyAnswersOptionsFlat = Object.values(guidToCard).flatMap(c => [
    ...c.question?.options.answers,
    ...c.question?.subjects?.headers,
  ])
  return conditions.some(({ referenceGuid, answers }) => {
    if (!referenceGuid) return false
    return answers.some(({ answerId, subjectId }) => {
      return surveyAnswersOptionsFlat.some(
        ({ id, omittedFromAudiences }) =>
          (id === answerId || id === subjectId) && omittedFromAudiences.size > 0,
      )
    })
  })
}
