import { eflStringToFilterNode, filterNodeToEFLString, type SavedFilter } from '@attest/segments'

import type { SavedFilterApiResponse } from './model'

export function transformSavedFiltersResponse(response: SavedFilterApiResponse[]): SavedFilter[] {
  return response.map<SavedFilter>(filterResponse => ({
    id: filterResponse.guid,
    name: filterResponse.name,
    efl: filterNodeToEFLString(eflStringToFilterNode(filterResponse.efl)) ?? '',
    isShared: filterResponse?.scope === 'ORG',
  }))
}

export function transformSavedFilterToRequest(filter: SavedFilter): SavedFilterApiResponse {
  return {
    guid: filter.id,
    name: filter.name,
    efl: filterNodeToEFLString(eflStringToFilterNode(filter.efl), true) ?? '',
    scope: filter.isShared ? 'ORG' : undefined,
  }
}
