import { FieldType, FilterBlock, FilterExpression, Logic, Operator } from '@attest/efl-antlr4'

import {
  ArrayOperator,
  type FilterNode,
  isFilterBlock,
  isFilterExpression,
  type Value,
} from './model'

export function createFilterExpression(
  key: string,
  operator: Operator,
  value: Value,
  options: ExpressionOptions,
): FilterExpression {
  const normalizedValue = Array.isArray(value) && value.length === 1 ? value[0] : value
  return new FilterExpression(
    key,
    createFieldType(value),
    // backwards support for includesany/includesnone operators
    operator,
    normalizedValue,
    !!options.not,
    options.strict,
    options.keyArrayOperator ?? (key.includes(`.*`) ? ArrayOperator.ANY : ArrayOperator.NONE),
    options.valueArrayOperator ??
      (Array.isArray(normalizedValue) ? ArrayOperator.ANY : ArrayOperator.NONE),
  )
}

function createFieldType(value: Value): FieldType {
  if (!Array.isArray(value)) {
    switch (typeof value) {
      case 'number':
        return FieldType.NUMBER
      case 'string':
        return FieldType.STRING
      default:
        return FieldType.NULL
    }
  }

  return createFieldType(value[0])
}

export function NOT(node: FilterNode): FilterNode {
  if (isFilterBlock(node)) {
    return new FilterBlock(node.items.map(NOT), Logic.OR)
  }

  if (isFilterExpression(node)) {
    return new FilterExpression(
      node.key,
      node.type,
      node.operator,
      node.value,
      true,
      node.strict,
      node.keyArray,
      node.valueArray,
    )
  }
  return node
}

export function INVERSE(expression: FilterExpression) {
  return new FilterExpression(
    expression.key,
    expression.type,
    expression.operator,
    expression.value,
    !expression.not,
    expression.strict,
    expression.keyArray,
    expression.valueArray,
  )
}

type ExpressionOptions = {
  not?: boolean
  strict?: boolean
  keyArrayOperator?: ArrayOperator
  valueArrayOperator?: ArrayOperator
}

export function IS(key: string, value: Value = null, options: ExpressionOptions = {}) {
  return createFilterExpression(key, Operator.EQ, value, options)
}

export function BETWEEN(
  key: string,
  value: [min: number, max: number],
  options: ExpressionOptions = {},
): FilterNode {
  return AND(
    createFilterExpression(key, Operator.GE, value[0], options),
    createFilterExpression(key, Operator.LE, value[1], options),
  )
}

export function LESS_THAN(key: string, value: number | number[], options: ExpressionOptions = {}) {
  return createFilterExpression(key, Operator.LT, value, options)
}

export function LESS_THAN_OR_EQUAL(key: string, value: number, options: ExpressionOptions = {}) {
  return createFilterExpression(key, Operator.LE, value, options)
}

export function GREATER_THAN(key: string, value: number, options: ExpressionOptions = {}) {
  return createFilterExpression(key, Operator.GT, value, options)
}
export function GREATER_THAN_OR_EQUAL(key: string, value: number, options: ExpressionOptions = {}) {
  return createFilterExpression(key, Operator.GE, value, options)
}

export function CONTAINS(key: string, value: string, options: ExpressionOptions = {}) {
  return createFilterExpression(key, Operator.CONTAINS, value, options)
}

export function STRICT_CONTAINS(key: string, value: string, options: ExpressionOptions = {}) {
  return CONTAINS(key, value, { strict: options.strict ?? true })
}

export function STARTS_WITH(key: string, value: string, options: ExpressionOptions = {}) {
  return createFilterExpression(key, Operator.STARTSWITH, value, options)
}

export function STRICT_STARTS_WITH(key: string, value: string, options: ExpressionOptions = {}) {
  return STARTS_WITH(key, value, { strict: options.strict ?? true })
}

export function ENDS_WITH(key: string, value: string, options: ExpressionOptions = {}) {
  return createFilterExpression(key, Operator.ENDSWITH, value, options)
}

export function STRICT_ENDS_WITH(key: string, value: string, options: ExpressionOptions = {}) {
  return ENDS_WITH(key, value, { strict: options.strict ?? true })
}

function createFilterNode(items: FilterNode[], logic: Logic): FilterNode {
  if (items.length === 1) {
    return items[0]
  }
  if (items.every(child => isSameBlockOrExpression(child, logic))) {
    return new FilterBlock(
      items.flatMap(item => (isFilterBlock(item) ? item.items : [item])),
      logic,
    )
  }

  return new FilterBlock(items, logic)
}

export function AND(...items: FilterNode[] | [FilterNode[]]): FilterNode {
  return createFilterNode(items.flat(), Logic.AND)
}

// Used where we want to preserve the items as a block ie `(item IS a AND item IS b)`
export function AND_GROUP(...items: FilterNode[] | [FilterNode[]]): FilterBlock {
  return new FilterBlock(items.flat(), Logic.AND)
}

export function OR(...items: FilterNode[] | [FilterNode[]]): FilterNode {
  return createFilterNode(items.flat(), Logic.OR)
}

// Used where we want to preserve the items as a block ie `(item IS a OR item IS b)`
export function OR_GROUP(...items: FilterNode[] | [FilterNode[]]): FilterBlock {
  return new FilterBlock(items.flat(), Logic.OR)
}

export function NONE(item?: FilterNode | [FilterNode]): FilterBlock {
  return new FilterBlock(Array.isArray(item) ? item : item === undefined ? [] : [item], Logic.NONE)
}

export function filterNodeToBlock(node: FilterNode): FilterBlock {
  if (isFilterExpression(node)) {
    return NONE(node)
  }

  if (isFilterBlock(node)) {
    return node
  }

  throw new Error('can not convert node to block')
}

function isSameBlockOrExpression(child: FilterNode, logic: Logic): boolean {
  return (
    (isFilterBlock(child) && (child.logic === logic || child.logic === Logic.NONE)) ||
    isFilterExpression(child)
  )
}
