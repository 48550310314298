<script lang="tsx">
import { defineComponent } from 'vue'

import { isElement } from '@attest/dom'
import { sum } from '@attest/util'
import { clamp, EventName, off, on, Queue } from '@attest/util'
import { create } from '@attest/vue-tsx'

type ProgressBarProps = {}

const SETTINGS = {
  minimum: 0.08,
  speed: 180,
}

type ProgressStage = 'render' | 'transitioning' | 'end'

const ProgressBar = defineComponent({
  data() {
    return {
      queue: new Queue(),
      status: null as number | null,
      hasError: false,
    }
  },

  computed: {
    isStarted(): boolean {
      return this.status !== null
    },
  },

  mounted() {
    on(EventName.PROGRESS_START, this.work)
    on(EventName.PROGRESS_INC, this.increment)
    on(EventName.PROGRESS_FINISH, this.finish)
  },

  beforeUnmount() {
    off(EventName.PROGRESS_START, this.work)
    off(EventName.PROGRESS_INC, this.increment)
    off(EventName.PROGRESS_FINISH, this.finish)
  },

  methods: {
    finish(payload: { status?: 'error' }) {
      if (payload.status === 'error') {
        this.hasError = true
      }

      this.done()
    },

    increment(payload: { status?: 'error' }) {
      if (payload.status === 'error') {
        this.hasError = true
      }

      this.work()
    },

    work() {
      if (!this.status) this.set(0)

      const work = (): void => {
        setTimeout(() => {
          if (!this.status) return
          this.trickle()
          work()
        }, SETTINGS.speed)
      }

      work()

      return this
    },

    done() {
      if (!this.status) return this
      this.queue.empty()
      const isDone = this.inc(0.3 + 0.5 * Math.random())
      if (isDone) isDone.set(1)
      return this
    },

    trickle() {
      return this.inc()
    },

    inc(amount?: number) {
      const status = this.status

      if (!status) return this.work()
      else if (status > 1) return

      if (amount === undefined) {
        amount = (1 - status) / 15
      }

      const newStatus = clamp(sum([status, amount]), { min: 0, max: 0.99 })

      return this.set(newStatus)
    },

    set(amount: number) {
      if (!this.isStarted) {
        requestAnimationFrame(() => {
          this.setStage('render')
          this.setScale(0)
        })
      }

      const clampedAmount = this.getClampedAmount(amount)
      this.status = clampedAmount === 1 ? null : clampedAmount

      this.queue.push(next => {
        requestAnimationFrame(() => {
          this.setStage('transitioning')
          this.setScale(clampedAmount)
        })

        if (clampedAmount !== 1) {
          setTimeout(next, SETTINGS.speed)
          return
        }

        setTimeout(() => {
          this.setStage('end')

          setTimeout(() => {
            this.remove()
            next()
          }, SETTINGS.speed)
        }, SETTINGS.speed)
      })

      return this
    },

    getClampedAmount(amount: number) {
      const precision = 1000
      const roundedAmount = Math.round(amount * precision) / precision
      return clamp(roundedAmount, { min: SETTINGS.minimum, max: 1 })
    },

    remove() {
      this.setScale(0)
      this.setStage(null)
      this.hasError = false
    },

    setStage(stage: ProgressStage | null) {
      const bar = this.$refs.bar
      if (!isElement(bar, HTMLElement)) {
        return
      }

      // eslint-disable-next-line unicorn/prefer-switch
      if (stage === 'render') {
        bar.style.transition = 'all 0 linear'
      } else if (stage === 'transitioning') {
        bar.style.transition = `all ${SETTINGS.speed}ms linear`
      } else if (stage === 'end') {
        bar.style.transition = `all ${SETTINGS.speed}ms linear`
        bar.style.opacity = '0'
      } else if (stage === null) {
        bar.style.transition = `background-color ${SETTINGS.speed}ms linear`
        bar.style.opacity = ''
      }
    },

    setScale(amount: number) {
      const bar = this.$refs.bar
      if (!isElement(bar, HTMLElement)) {
        return
      }

      bar.style.transform = `translateZ(0) scaleX(${amount})`
    },
  },

  render() {
    const baseClass = 'c-progress-bar'
    const loadingContainerClass = {
      [baseClass]: true,
      [`${baseClass}--error`]: this.hasError,
    }

    return (
      <div class={loadingContainerClass}>
        <div
          ref="bar"
          class={`${baseClass}__bar`}
          style={{ transform: `translateZ(0) scaleX(0)` }}
        />
      </div>
    )
  },
})

export default create<ProgressBarProps>(ProgressBar)
</script>
<style lang="postcss">
.c-progress-bar {
  position: relative;
  z-index: var(--z-index-progress);
  height: 0;
  pointer-events: none;

  &__bar {
    height: 2px;
    background-color: var(--attest-color-interactive-default);
    transform-origin: left;
    transition: background-color var(--attest-timing-default) linear;
    will-change: transform;
  }

  &--error &__bar {
    background-color: var(--attest-color-interactive-negative-default);
  }
}
</style>
