import { defineComponent, type Plugin } from 'vue'

import {
  createSynchronisedObjectStorage,
  type SchemaBase,
  type SynchronisedObjectStorage,
} from './synchronised-object-storage'

const UNSUPPORTED_OPERATION = 'reactive-storage: unsupported operation.'
const { triggerBeforeMount, beforeMount } = (() => {
  let mounted = false
  const fns: (() => void)[] = []

  return {
    triggerBeforeMount: () => {
      mounted = true
      for (const fn of fns) {
        fn()
      }
    },
    beforeMount: (fn: () => void): void => {
      if (mounted) fn()
      else fns.push(fn)
    },
  }
})()

export default {
  install: app => {
    app.mixin(
      defineComponent({
        beforeMount() {
          const isRoot = !this.$parent
          if (isRoot) triggerBeforeMount()
        },
      }),
    )
  },
} as Plugin<void>

type ReactiveStorageOptions<Schema extends SchemaBase> = {
  storage?: Storage
  schema: Schema
}

export function createReactiveStorage<Schema extends SchemaBase>(
  opts: ReactiveStorageOptions<Schema>,
): SynchronisedObjectStorage<Schema> {
  const { storage = createImmutableStorage(), schema } = opts

  const reactiveStorage = createSynchronisedObjectStorage({
    storage,
    object: Object.fromEntries(Object.keys(schema).map(key => [key, null])) as {
      [Key in keyof Schema]: any
    },
    schema,
  })

  beforeMount(() => reactiveStorage.loadFromStorage())

  return reactiveStorage
}

function createImmutableStorage(): Storage {
  const IMMUTABLE_STORAGE_WARNING_MESSAGE = 'Cannot mutate ImmutableStorage.'

  return {
    get length() {
      return 0
    },

    clear() {
      throw new Error(IMMUTABLE_STORAGE_WARNING_MESSAGE)
    },

    getItem() {
      return null
    },

    removeItem() {
      throw new Error(IMMUTABLE_STORAGE_WARNING_MESSAGE)
    },

    setItem() {
      throw new Error(IMMUTABLE_STORAGE_WARNING_MESSAGE)
    },

    key(): string | null {
      throw new Error(UNSUPPORTED_OPERATION)
    },
  }
}
