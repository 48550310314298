import { defineStore } from 'pinia'
import type { DeepPartial } from 'ts-essentials'
import { type RouteLocation, useRouter } from 'vue-router'

import type { InternalError, ServiceError } from '@attest/_api'
import type { ColourTheme } from '@attest/results-colors'
import { STUDY_INSIGHTS_ROUTE_NAME } from '@attest/router'
import { useStudyStore } from '@attest/study'
import { capture } from '@attest/telemetry'
import { useUserStore } from '@attest/user'
import { deepEqual, mergeDeep } from '@attest/util'

import * as api from './api'
import { getDefaultCardSettings } from './factory/settings.factory'
import type {
  SavedCardIdToSavedCardSettings,
  SavedCardSettings,
  SavedStudySettings,
} from './model/saved-settings'

type SavedSettingsState = {
  studyGuid: string | null
  settings: SavedCardIdToSavedCardSettings
  studySettings: SavedStudySettings
  organisationSettings: {
    colours: { default: string[] }
  }
  settingsVersion: number
  organisationSettingsVersion: number
}

export const SAVED_SETTINGS_STORE_NAMESPACE = 'savedSettings'

export function createSettingsStoreState(
  override: Partial<SavedSettingsState> = {},
): SavedSettingsState {
  return {
    studyGuid: null,
    settings: {},
    studySettings: {
      chartTheme: 'attest',
      overview: {
        order: 'draft',
        show: {
          percentages: true,
          values: true,
          forward_percentage: false,
        },
      },
      trends: {
        order: 'draft',
        show: {
          percentages: true,
          forward_percentage: false,
        },
      },
      analysis: {
        order: 'draft',
        show: {
          percentages: true,
          forward_percentage: false,
        },
      },
      crosstabs: {
        order: 'draft',
        show: {
          percentages: true,
          values: true,
          forward_percentage: false,
        },
      },
    },
    organisationSettings: {
      colours: {
        default: [],
      },
    },
    settingsVersion: 0,
    organisationSettingsVersion: 0,
    ...override,
  }
}

export type ContextKey = 'crosstabs' | 'overview' | 'trends'

function isOverview($route: RouteLocation): boolean {
  return $route.name === STUDY_INSIGHTS_ROUTE_NAME.OVERVIEW
}

function isTrends($route: RouteLocation): boolean {
  return $route.name === STUDY_INSIGHTS_ROUTE_NAME.TRENDS
}

export const useSavedSettingsStore = defineStore(SAVED_SETTINGS_STORE_NAMESPACE, {
  state: createSettingsStoreState,

  getters: {
    getSettingsByCardId(): (cardId: string) => SavedCardSettings {
      return (cardId: string) => this.settings[cardId]
    },

    hasCardWithCustomOrder(): boolean {
      return false
    },

    contextKey(): ContextKey {
      const router = useRouter()
      if (!router || !router.currentRoute.value) {
        return 'crosstabs'
      }

      if (isTrends(router.currentRoute.value)) {
        return 'trends'
      }
      if (isOverview(router.currentRoute.value)) {
        return 'overview'
      }

      return 'crosstabs'
    },

    activeStudySettings(): SavedSettingsState['studySettings'][ContextKey] {
      return this.studySettings[this.contextKey]
    },
  },

  actions: {
    async get(studyGuid: string): Promise<InternalError<ServiceError<never>> | void> {
      if (!useUserStore().isAuthenticated) {
        this.setInitialCardSettings()
        this.studyGuid = studyGuid
        return
      }

      if (this.studyGuid === studyGuid) return
      try {
        const savedSettings = await api.getSavedSettings(studyGuid)
        const savedOrganisationSettings = await api.getSavedOrganisationSettings()

        const cardIds = Object.keys(useStudyStore().cardIdToCard)
        const settings: SavedCardIdToSavedCardSettings = {}

        this.organisationSettings = savedOrganisationSettings.settings
        this.organisationSettingsVersion = savedOrganisationSettings.settingsVersion

        if (!savedSettings.study) {
          this.setInitialStudySettings()
        }

        if (!savedSettings.study?.trends) {
          this.setInitialStudyTrendsSettings()
        } else {
          this.studySettings.trends = savedSettings.study.trends
        }
        if (!savedSettings.study?.analysis) {
          this.setInitialStudyAnalysisSettings()
        } else {
          this.studySettings.analysis = savedSettings.study.analysis
        }
        if (!savedSettings.study?.crosstabs) {
          this.setInitialStudyCrosstabsSettings()
        } else {
          this.studySettings.crosstabs = savedSettings.study.crosstabs
        }
        if (!savedSettings.study?.overview) {
          this.setInitialStudyOverviewSettings()
        } else {
          this.studySettings.overview = savedSettings.study.overview
        }

        if (!savedSettings.study?.chartTheme) {
          this.studySettings.chartTheme = 'attest'
        } else {
          this.studySettings.chartTheme = savedSettings.study?.chartTheme
        }

        if (!savedSettings.cards) {
          this.setInitialCardSettings()
          return
        }

        cardIds.forEach(cardId => {
          const storedSettings = savedSettings.cards[cardId] ?? {}
          if (storedSettings.overview?.selected_visualisation) {
            storedSettings.overview.selected_visualisation =
              storedSettings.overview.selected_visualisation.toLowerCase() as SavedCardSettings['overview']['selected_visualisation']
          }
          const defaultSettings = getDefaultCardSettings(useStudyStore().cardIdToCard[cardId])
          if (!defaultSettings) return
          settings[cardId] = storedSettings
            ? mergeDeep(defaultSettings, storedSettings)
            : defaultSettings
        })
        this.settings = settings
        this.settingsVersion = savedSettings.settingsVersion
      } catch (error) {
        this.setInitialCardSettings()
        capture(error)
      } finally {
        this.studyGuid = studyGuid
      }
    },

    async update(cardId: string, setting: DeepPartial<SavedCardSettings>) {
      const cardSettings = this.settings[cardId]

      if (!cardSettings) return

      const updated = mergeDeep(cardSettings, setting) as SavedCardSettings

      if (deepEqual(cardSettings, updated)) return

      this.settings[cardId] = updated

      if (!useUserStore().isAuthenticated || !this.studyGuid) return

      try {
        const newSettingsVersion = this.settingsVersion + 1
        api.updateSavedSettings(
          this.studyGuid,
          this.settings,
          newSettingsVersion,
          this.studySettings,
        )
        this.settingsVersion = newSettingsVersion
      } catch (e) {
        throw new Error(e)
      }
    },

    async updateStudyTheme(theme: ColourTheme) {
      this.studySettings.chartTheme = theme
      Object.keys(this.settings).forEach((cardId: string) => {
        if (this.settings[cardId].crosstabs && this.settings[cardId].crosstabs.theme !== theme) {
          this.settings[cardId].crosstabs.answerIdToThemeColour = undefined
          this.settings[cardId].crosstabs.theme = theme
        }
        if (this.settings[cardId].overview.theme !== theme) {
          this.settings[cardId].overview.answerIdToThemeColour = undefined
          this.settings[cardId].overview.theme = theme
        }
        if (this.settings[cardId].trends.theme !== theme) {
          this.settings[cardId].trends.answerIdToThemeColour = undefined
          this.settings[cardId].trends.theme = theme
        }
      })
      await this.updateStudySettings()
    },

    async updateStudySettings() {
      if (!useUserStore().isAuthenticated || !this.studyGuid) return

      try {
        const newSettingsVersion = this.settingsVersion + 1
        api.updateSavedSettings(
          this.studyGuid,
          this.settings,
          newSettingsVersion,
          this.studySettings,
        )
        this.settingsVersion = newSettingsVersion
      } catch (e) {
        throw new Error(e)
      }
    },

    async updateSavedOrganisationSettings() {
      if (!useUserStore().isAuthenticated) {
        return
      }

      try {
        const newSettingsVersion = this.organisationSettingsVersion + 1
        api.updateSavedOrganisationSettings(this.organisationSettings, newSettingsVersion)
        this.organisationSettingsVersion = newSettingsVersion
      } catch (e) {
        throw new Error(e)
      }
    },

    setInitialCardSettings() {
      const studyStore = useStudyStore()
      const cardIds = Object.keys(studyStore.cardIdToCard)
      const settings: SavedCardIdToSavedCardSettings = {}
      cardIds.forEach(cardId => {
        const defaultSettings = getDefaultCardSettings(studyStore.cardIdToCard[cardId])
        if (!defaultSettings) return
        settings[cardId] = defaultSettings
      })
      this.settings = settings
    },

    setInitialStudySettings() {
      this.studySettings = {
        chartTheme: 'attest',
        trends: {
          order: 'draft',
          show: {
            percentages: true,
            forward_percentage: false,
          },
        },
        analysis: {
          order: 'draft',
          show: { percentages: true, forward_percentage: false },
        },
        overview: {
          order: 'draft',
          show: {
            values: true,
            percentages: true,
            forward_percentage: false,
          },
        },
        crosstabs: {
          order: 'draft',
          show: { values: true, percentages: true, forward_percentage: false },
        },
      }
    },

    setInitialStudyTrendsSettings() {
      this.studySettings.trends = {
        order: 'draft',
        show: {
          percentages: true,
          forward_percentage: false,
        },
      }
    },

    setInitialStudyAnalysisSettings() {
      this.studySettings.analysis = {
        order: 'draft',
        show: {
          percentages: true,
          forward_percentage: false,
        },
      }
    },

    setInitialStudyOverviewSettings() {
      this.studySettings.overview = {
        order: 'draft',
        show: {
          values: true,
          percentages: true,
          forward_percentage: false,
        },
      }
    },

    setInitialStudyCrosstabsSettings() {
      this.studySettings.crosstabs = {
        order: 'draft',
        show: {
          percentages: true,
          values: true,
          forward_percentage: false,
        },
      }
    },
  },
})
