<script lang="tsx">
import { defineComponent, type ExtractPublicPropTypes, type PropType } from 'vue'

export type AttestGradientLabelProps = ExtractPublicPropTypes<typeof attestGradientLabelProps>

const attestGradientLabelProps = {
  type: { type: String as PropType<'NEW' | 'BETA'>, default: 'NEW' },
  label: { type: String, default: 'NEW' },
}

export default defineComponent({
  props: attestGradientLabelProps,
  setup(props, { slots }) {
    const baseClass = 'c-attest-gradient-label'
    return () => {
      return (
        <div
          class={{
            [`${baseClass}`]: true,
            [`${baseClass}--new`]: props.type === 'NEW',
            [`${baseClass}--beta`]: props.type === 'BETA',
          }}
        >
          {slots?.default?.() ?? props.label}
        </div>
      )
    }
  },
})
</script>
<style lang="postcss">
.c-attest-gradient-label {
  --gradient-start-colour: #feb080;
  --gradient-end-colour: #ffde89;

  &--beta {
    --gradient-start-colour: #f2b4d9;
    --gradient-end-colour: #ffd6ea;
  }
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  padding: 0 var(--attest-spacing-2) 0 var(--attest-spacing-1);
  border-radius: var(--attest-border-radius-s);
  background: linear-gradient(
    94deg,
    var(--gradient-start-colour) 9.16%,
    var(--gradient-end-colour) 61.59%
  );
  color: var(--attest-color-text-default);
  cursor: default;
  font-size: var(--attest-font-size-s);
  font-weight: var(--attest-font-weight-bold);
}
</style>
