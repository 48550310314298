import { useMemoize } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, type ComputedRef, ref, type Ref } from 'vue'

import {
  useCardResponseFiltersStore,
  useResultsDemographicFiltersStore,
  useSegmentFiltersStore,
} from '@attest/results-saved-filters'
import { useSavedSettingsStore } from '@attest/results-saved-settings'
import {
  AND,
  createRoundAnswerIdsFilterExpression,
  createRoundAnswersOrderFilterNode,
  createRoundApprovedFilterExpression,
  createRoundCardIdFilterExpression,
  createRoundIdsFilterExpression,
  createRoundSegmentationsFilterNode,
  createRoundSegmentFilterNode,
  createRoundsFilter,
  createRoundSuccessfulFilterExpression,
  createRoundSurveyIdsFilterExpression,
  filterNodeToEFLString,
  OR,
  type Round,
} from '@attest/rounds'
import type { RoundCard } from '@attest/rounds'
import { useStudyStore } from '@attest/study'
import { isDefined } from '@attest/util'

import type { StudyResultsAnswer, StudyResultsRespondent } from '../models'

import { useStudyInsightsStore } from './study-insights'
import { useStudyResultsStore } from './study-results'
import { useStudyWaveFiltersStore } from './wave-filters'

export const useResultsRoundsStore = defineStore('resultsRounds', () => {
  const rounds = ref<Set<Round> | undefined>(undefined)
  const all = useRoundsAll({ rounds })
  const active = useRoundsActive(all)
  const successful = useRoundsSuccessful(active)
  const approved = useRoundsApproved(active)

  const filteredAnswers = useRoundsFilteredAnswers(active)
  const filteredSegments = useRoundsFilteredSegments(filteredAnswers)
  const filteredDemographics = useRoundsFilteredDemographics(filteredSegments)
  const successfulFiltered = useRoundsSuccessful(filteredDemographics)

  const successfulSubjectValueToRounds = useDemographicValueToRounds(successful)
  const successfulFilteredSubjectValueToRounds = useDemographicValueToRounds(successfulFiltered)

  const card = useMemoize((payload: { id: string }) => ({
    filtered: useRoundsCard(filteredDemographics, payload),
    active: useRoundsCard(active, payload),
  }))

  return {
    rounds,
    all: computed(() => all),
    active: computed(() => active),
    successful: computed(() => successful),
    approved: computed(() => approved),
    card,
    cardWithBase: useMemoize(
      (payload: { id: string; subjectIds: string[]; optionIds: string[] }) => ({
        filtered: useRoundsWithBase(card(payload).filtered, payload),
        active: useRoundsWithBase(card(payload).active, payload),
      }),
    ),
    filtered: computed(() => filteredDemographics),
    successfulFiltered: computed(() => successfulFiltered),
    successfulSubjectValueToRounds,
    successfulFilteredSubjectValueToRounds,
  }
})

function useRoundsActive(base: RoundsWithEFL): RoundsWithEFL {
  return useRoundsWithEFL({
    base,
    efl: computed(() => {
      const surveys = useStudyWaveFiltersStore().filteredSentWaveSurveys.map(({ id }) => id)
      if (surveys.length === 0) return undefined
      return createRoundSurveyIdsFilterExpression(surveys)
    }),
  })
}

function useRoundsSuccessful(base: RoundsWithEFL): RoundsWithEFL {
  return useRoundsWithEFL({
    base,
    efl: computed(() => createRoundSuccessfulFilterExpression()),
  })
}

function useRoundsApproved(base: RoundsWithEFL): RoundsWithEFL {
  return useRoundsWithEFL({
    base,
    efl: computed(() => createRoundApprovedFilterExpression()),
  })
}

function useRoundsFilteredDemographics(base: RoundsWithEFL): RoundsWithEFL {
  return useRoundsWithEFL({
    base,
    efl: computed(() => {
      const resultsDemographicFiltersStore = useResultsDemographicFiltersStore()
      const studyInsightsStore = useStudyInsightsStore()
      if (
        !resultsDemographicFiltersStore.shouldFilter ||
        !studyInsightsStore.shouldUsePanelFilters
      ) {
        return undefined
      }

      return AND(
        Object.entries(resultsDemographicFiltersStore.nameToFilters).map(([name, filter]) =>
          createRoundSegmentationsFilterNode(name, filter),
        ),
      )
    }),
  })
}

function useRoundsCard(base: RoundsWithEFL, card: { id: string }): RoundsWithEFL {
  return useRoundsWithEFL({
    base,
    efl: computed(() => createRoundCardIdFilterExpression(card.id)),
  })
}

function useRoundsWithBase(
  base: RoundsWithEFL,
  payload: { id: string; subjectIds: string[]; optionIds: string[] },
): RoundsWithEFL {
  return useRoundsWithEFL({
    base,
    efl: computed(() => {
      if (!useSavedSettingsStore().activeStudySettings.show.forward_percentage) {
        return undefined
      }

      const structure = useStudyInsightsStore().getMergedQuestionStructureByCardId(payload.id)

      const optionMasks = structure.options
        .filter(option => payload.optionIds.includes(option.id))
        .map(item => item.mask)
        .filter(isDefined)
      const subjectMasks = structure.subjects
        .filter(subject => payload.subjectIds.includes(subject.id))
        .map(item => item.mask)
        .filter(isDefined)

      const masks = [...optionMasks, ...subjectMasks]
      if (masks.length === 0) {
        return undefined
      }

      return OR(
        ...masks.map(mask => createRoundAnswerIdsFilterExpression(mask.cardId, [mask.answerId])),
      )
    }),
  })
}

function useRoundsFilteredAnswers(base: RoundsWithEFL): RoundsWithEFL {
  return useRoundsWithEFL({
    base,
    efl: computed(() => {
      const cardResponseFiltersStore = useCardResponseFiltersStore()
      const studyInsightsStore = useStudyInsightsStore()
      if (!cardResponseFiltersStore.shouldFilter || !studyInsightsStore.shouldUsePanelFilters) {
        return undefined
      }

      // TODO: in order to clean this up we will need to refactor the overview cards
      // so that they don't use card summary objects and use EFL directly and also replace this
      // the cardResponseFiltersStore so that we have a better data model that works with efl better
      return AND(
        ...Object.entries(cardResponseFiltersStore.cardIdToFilters).flatMap(([cardId, filters]) => {
          if (filters.responseIds.length > 0) {
            return createRoundIdsFilterExpression(filters.responseIds)
          }

          if (Object.keys(filters.answerIdsToResponseIds).length > 0) {
            const resultCard = studyInsightsStore.cardIdToResultsCard[cardId]
            return Object.entries(filters.answerIdsToResponseIds)
              .map<[string, Set<number>]>(([answerId, responseIds]) => [
                answerId,
                new Set(
                  responseIds
                    .map(id => resultCard.answerIdToAnswer[answerId].responseIdToResponse[id])
                    .filter(isDefined)
                    .map(response => ('order' in response ? response.order : undefined))
                    .filter(isDefined),
                ),
              ])
              .map(([answerId, orders]) =>
                createRoundAnswersOrderFilterNode(cardId, [answerId], [...orders]),
              )
          }

          return createRoundAnswerIdsFilterExpression(cardId, filters.answerIds)
        }),
      )
    }),
  })
}

function useRoundsFilteredSegments(base: RoundsWithEFL): RoundsWithEFL {
  return useRoundsWithEFL({
    base,
    efl: computed(() => {
      const segmentFiltersStore = useSegmentFiltersStore()
      const studyInsightsStore = useStudyInsightsStore()
      const { currentSegmentFilter, shouldFilter } = segmentFiltersStore
      const efl = currentSegmentFilter?.efl
      if (!shouldFilter || !efl || !studyInsightsStore.shouldUsePanelFilters) {
        return undefined
      }

      return createRoundSegmentFilterNode(efl)
    }),
  })
}

function respondentToRoundCard(
  respondent: StudyResultsRespondent,
  cards: { id: string; answers: (StudyResultsAnswer | undefined)[] }[],
): Record<string, RoundCard> {
  return Object.fromEntries(
    cards
      .map(card => {
        const answerEntries = card.answers
          .map(answer => {
            if (!answer) {
              return undefined
            }
            const response = answer.responseIdToResponse?.[respondent.id] ?? {}

            if (!answer.responseIds.has(respondent.id)) {
              return undefined
            }

            return [
              answer.id,
              {
                ...('text' in response ? { text: response.text } : {}),
                ...('sentiment' in response ? { sentiment: response.sentiment } : {}),
                ...('order' in response ? { order: response.order } : {}),
                ...('videoId' in response ? { videoId: response.videoId } : {}),
                ...('transcript' in response ? { transcript: response.transcript } : {}),
              },
            ]
          })
          .filter(isDefined)

        if (answerEntries.length === 0) {
          return undefined
        }

        return [card.id, { answers: Object.fromEntries(answerEntries) }]
      })
      .filter(isDefined),
  )
}

function useRoundsAll(refs: { rounds: Ref<Set<Round> | undefined> }): RoundsWithEFL {
  const studyResultsStore = useStudyResultsStore()
  const studyStore = useStudyStore()

  const rounds = computed<Set<Round>>(() => {
    if (refs.rounds.value !== undefined) {
      return refs.rounds.value
    }
    if (studyStore.study) {
      const cards = Object.values(studyResultsStore.cardIdToCard).map(card => ({
        id: card.id,
        answers: Object.values(card.answerIdToAnswer),
      }))
      return new Set(
        [...Object.keys(studyResultsStore.respondentIdToRespondent)]
          .map(id => {
            const respondent = studyResultsStore.respondentIdToRespondent[id]
            const waveSurvey = studyStore.waveSurveyIdToWaveSurvey[respondent.waveSurveyId]
            const publishedTimestamp = waveSurvey?.publishedTimestamp

            if (publishedTimestamp === null || publishedTimestamp === undefined) {
              return undefined
            }
            return Object.freeze({
              id,
              audience: { id: waveSurvey.audienceId, country: waveSurvey.country },
              survey: { id: respondent.waveSurveyId },
              waveTimestamp: publishedTimestamp,
              demographics: respondent.nameToSegmentation,
              cards: respondentToRoundCard(respondent, cards),
              outcome: respondent.outcome,
            })
          })
          .filter(isDefined),
      )
    }

    return new Set<Round>()
  })

  return {
    filter: createRoundsFilter(rounds),
    rounds,
    efl: computed(() => undefined),
    eflString: computed(() => undefined),
  }
}

export function roundsToIds(rounds: Set<Round>): Set<string> {
  return new Set([...rounds].map(round => round.id))
}

export function roundsRefToIds(rounds: Ref<Set<Round>>): ComputedRef<Set<string>> {
  return computed(() => roundsToIds(rounds.value))
}

type RoundsWithEFL = {
  filter: (node: unknown) => Ref<Set<Round>>
  rounds: ComputedRef<Set<Round>>
  efl: ComputedRef<unknown | undefined>
  eflString: ComputedRef<string | undefined>
}
function useRoundsWithEFL({
  base,
  efl,
}: {
  base: RoundsWithEFL
  efl: ComputedRef<unknown | undefined>
}): RoundsWithEFL {
  const rounds = computed(() => (efl.value ? base.filter(efl.value).value : base.rounds.value))
  const filter = createRoundsFilter(rounds)
  const combinedEFL = computed(() => {
    return base.efl.value === undefined
      ? efl.value
      : AND([base.efl.value, ...(efl.value ? [efl.value] : [])] as any[])
  })
  const eflString = computed(() => {
    return combinedEFL.value === undefined
      ? undefined
      : filterNodeToEFLString(combinedEFL.value as any)
  })

  return {
    rounds,
    filter,
    efl: combinedEFL,
    eflString,
  }
}

/**
 * This creates a hashmap of demopraphics to rounds. This is much more efficient than
 * manually calling efl queries in the demographic sidebar. As we have all the rounds
 * information to compose this hashmap.
 *
 * The time complexity of this function is O(n*m), where n is the number of rounds
 * and m is the number of demopraphics in each round. This is because the function iterates
 * through each round and each subject within that round to create the result object.
 *
 * The space complexity of this function is O(n*m) as well. This is because the result object
 * stores information about each subject value and the rounds associated with it, so the space
 * required grows linearly with the number of rounds and demopraphics.
 *
 * The alternative would be a complexity of O(n^2) which although, O(n*m) could be considered
 * worse performance if m is greater than n we know that m is fairly constant as a low number.
 * So for smaller amount of rounds the performance will be worse, but in most real life
 * scenarios the rounds are greater than the total amount subject values.
 */
function useDemographicValueToRounds(
  base: RoundsWithEFL,
): ComputedRef<Record<string, Record<string, Set<Round>>>> {
  return computed(() => {
    const result: Record<string, Record<string, Set<Round>>> = {}
    for (const round of base.rounds.value) {
      for (const [name, segmentation] of Object.entries(round.demographics)) {
        if (segmentation === undefined) continue
        result[name] ??= {}

        for (const value of Array.isArray(segmentation) ? segmentation : [segmentation]) {
          result[name][value] ??= new Set()
          result[name][value].add(round)
        }
      }
    }

    return result
  })
}
