import type { Merge } from 'ts-essentials'

import { mergeDeepAll, omit } from '@attest/util'

import { sortMessageStructuresByAscendingPublishedTimestamp } from '../..'

import type { MergedStudyMessageStructure, StudyMessageStructure } from './model'

export function createStudyMessageStructure(
  override: Merge<
    Partial<StudyMessageStructure>,
    {
      waveId: string
    }
  >,
): StudyMessageStructure {
  return Object.freeze({
    waveId: override.waveId,
    nextGuid: override.nextGuid ?? null,
    text: override.text ?? '',
    publishedTimestamp: override.publishedTimestamp ?? undefined,
    imageUrl: override.imageUrl ?? undefined,
    omitted: override.omitted ?? false,
  })
}

export function createMergedMessageStructure(
  structures: StudyMessageStructure[],
): MergedStudyMessageStructure {
  return omit(mergeDeepAll(sortMessageStructuresByAscendingPublishedTimestamp(structures)), [
    'waveId',
  ])
}
