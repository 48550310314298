import multipleChoiceSVG from '@attest/_assets/icons/check_box.svg'
import groupSVG from '@attest/_assets/icons/dashboard_customize.svg'
import textCardSVG from '@attest/_assets/icons/font_download.svg'
import gridsSVG from '@attest/_assets/icons/grid_on.svg'
import rankedSVG from '@attest/_assets/icons/keyboard_double_arrow_up.svg'
import singleChoiceSVG from '@attest/_assets/icons/radio_button_checked.svg'
import npsSVG from '@attest/_assets/icons/speed.svg'
import freeTextSVG from '@attest/_assets/icons/title.svg'
import videoSVG from '@attest/_assets/icons/video_front.svg'
import { COPY } from '@attest/_copy'
import type { CardError } from '@attest/_lib/src/editor-error/editor-error'
import { translationKey } from '@attest/_lib/src/translation/translation-key'
import {
  type Answer,
  type Card,
  type CardGuid,
  type CardPosition,
  type CardQuestionType,
  type CardType,
  type CombinedCardType,
  type CombinedCardTypeQuestion,
  type CombinedCardTypeText,
  createCombinedCardType,
  createEmptyAnswer,
  createEmptyAnswers,
  createEmptyCardQuestionOptions,
  createEmptyCardQuestionSubjects,
  createEmptyHeaders,
  createEmptyTextCard,
  type Header,
  isCardNpsQuestion,
  isCombinedCardTypeGroup,
  isCombinedCardTypeQuestion,
  isCombinedCardTypeText,
  isGroupCard,
  isTextCard,
  type OptionAnswer,
} from '@attest/editor-card'
import type { RouteCard } from '@attest/editor-routing-graph'
import { SETTINGS } from '@attest/editor-settings'
import { c } from '@attest/intl'
import { maxByList, minByList } from '@attest/util'

type SurveyCardRecord = Record<CardGuid, Card>

type Survey = {
  cards: SurveyCardRecord
}

export function getNextFreePositionOnRow(position: CardPosition, cards: Card[]): CardPosition {
  const nextPosition = { x: position.x + 1, y: position.y }

  if (!cards.some(card => isSamePosition(nextPosition, card.position))) {
    return nextPosition
  }

  return getNextFreePositionOnRow(nextPosition, cards)
}

export function getFreePositionAfterLastCardOnRow(
  position: CardPosition,
  cards: Card[],
): CardPosition {
  const cardsOnSameRow = getCardsOnRow(cards, position.y)
  return {
    x: Math.max(...cardsOnSameRow.map(({ position: pos }) => pos.x)) + 1,
    y: position.y,
  }
}

export function hasFilledAnswers(card: Card): boolean {
  return card.question.options.answers.some(
    answer => answer.text.length > 0 || Boolean(answer.media?.url),
  )
}

export function hasFilledGridSubjects(card: Card): boolean {
  return card.question.subjects.headers.some(header => header.text.length > 0)
}

export function isSamePosition(positionA: CardPosition, positionB: CardPosition): boolean {
  return positionA.x === positionB.x && positionA.y === positionB.y
}

export function getCardsDirectlyToRight(card: Card, cards: Card[]): Card[] {
  const sortedCardsToRight = getCardsOnRow(cards, card.position.y)
    .filter(({ position }) => isRightOf(position, card.position))
    .sort((card1, card2) => card1.position.x - card2.position.x)

  if (sortedCardsToRight[0]?.position.x !== card.position.x + 1) {
    return []
  }

  const lastNeighbourIndex = sortedCardsToRight
    .map(({ position }) => position.x)
    .findIndex((positionX, index, xValues) => {
      if (index === xValues.length - 1) return true
      return positionX + 1 !== xValues[index + 1]
    })

  return sortedCardsToRight.slice(0, lastNeighbourIndex + 1)
}

export function getLeftCard(card: Card, cards: Card[]): Card | null {
  const row = sortCardsOnSameRow(getCardsOnRow(cards, card.position.y))
  const index = row.findIndex(({ guid }) => guid === card.guid)
  return row[index - 1] ?? null
}

export function getCardTypeDisplay(combinedCardType: CombinedCardType): string {
  if (isCombinedCardTypeText(combinedCardType)) {
    return COPY.CARD_TYPES.TEXT
  }

  if (isCombinedCardTypeGroup(combinedCardType)) {
    return COPY.CARD_TYPES.GROUP
  }

  if (isCombinedCardTypeQuestion(combinedCardType) && combinedCardType.subType !== null) {
    return getQuestionTypeDisplay(combinedCardType.subType)
  }

  return COPY.QUESTION(1)
}

export function getNumberQuestions<C extends RouteCard>(cards: readonly C[]): number {
  return cards
    .flatMap(card => (card.type === 'group' ? card.group.cards : card))
    .filter(({ type }) => type === 'question').length
}

export function getCardNumbersFromOrderedCards<C extends RouteCard>(
  cardsInServingOrder: C[],
): Record<CardGuid, number> {
  const questionCards = cardsInServingOrder.filter(
    ({ type }) => type === 'question' || type === null,
  )
  const textCards = cardsInServingOrder.filter(({ type }) => type === 'text')
  const groupCards = cardsInServingOrder.filter(({ type }) => type === 'group')

  const getIndex = (card: C): number => {
    switch (card.type) {
      case 'group':
        return groupCards.indexOf(card) + 1
      case 'text':
        return textCards.indexOf(card) + 1
      default:
        return questionCards.indexOf(card) + 1
    }
  }

  return Object.fromEntries<number>(cardsInServingOrder.map(card => [card.guid, getIndex(card)]))
}

export function getIdDisplay(cardNumber: number, cardType: CardType | null): string {
  return `${getIdPrefix(cardType)}${cardNumber}`
}

export function isImageAnswers(answers: Answer[]): answers is Answer<'image'>[] {
  return answers.every(isImageAnswer)
}

export function sortCardsInRows(cards: Card[]): Card[][] {
  type Row = Card[]
  const rowsMap = new Map<number, Row>()

  cards.forEach(card => {
    const { y } = card.position
    const row: Row = rowsMap.get(y) || []
    row.push(card)
    rowsMap.set(y, row)
  })

  return Array.from(rowsMap.values())
    .sort(([card1], [card2]) => (card1?.position.y ?? 0) - (card2?.position.y ?? 0))
    .map(sortCardsOnSameRow)
}

export function getCardsOnRow(cards: Card[], row: number): Card[] {
  return cards.filter(card => card.position.y === row)
}

export function sortCardsOnSameRow(cards: Card[]): Card[] {
  return cards.sort((card1, card2) => card1.position.x - card2.position.x)
}

export function findFirstRight(card: Card, cards: Card[]): Card | null {
  const cardsOnTheRight = cards.filter(({ position }) => isRightOf(position, card.position))
  const firstCardOnTheRight = minByList(({ position }) => position.x, cardsOnTheRight)
  return firstCardOnTheRight || null
}

export function findCardByGuid<C extends { guid: string }>(
  guid: string | null,
  cards: C[],
): C | null {
  if (!guid) return null
  return cards.find(card => card.guid === guid) || null
}

export function hasImage(element: Card | OptionAnswer): boolean {
  return !!element.media && !!element.media.url
}

function isImageAnswer(answer: Answer): answer is Answer<'image'> {
  return answer.type === 'image'
}
export function isCardVideoQuestion(card: Card): boolean {
  return card.question.type === 'video'
}
function getQuestionTypeDisplay(type: CardQuestionType): string {
  const { CARD_TYPES } = COPY

  switch (type) {
    case 'grid':
      return CARD_TYPES.GRID
    case 'multiple_choice':
      return CARD_TYPES.MULTIPLE_CHOICE
    case 'nps':
      return CARD_TYPES.NPS
    case 'ranked':
      return CARD_TYPES.RANKED
    case 'single_choice':
      return CARD_TYPES.SINGLE_CHOICE
    case 'open_text':
      return CARD_TYPES.FREE_TEXT
    case 'video':
      return c('question_type.video')
    default:
      throw new Error(`Unknown card type: ${type}`)
  }
}

function getIdPrefix(cardType: CardType | null): string {
  if (cardType === 'text') return COPY.CARD.TEXT_ID_PREFIX
  if (cardType === 'group') return COPY.CARD.GROUP_ID_PREFIX
  return COPY.CARD.QUESTION_ID_PREFIX
}

function isRightOf(position1: CardPosition, position2: CardPosition): boolean {
  return position1.y === position2.y && position1.x > position2.x
}

export function getNextGuids(card: Card): CardGuid[] {
  return [
    card.nextGuid,
    ...(card.type === 'question'
      ? card.question.options.answers.map(({ nextGuid }) => nextGuid)
      : [null]),
  ].flatMap(guid => (guid !== null ? [guid] : []))
}

export function trimCardTexts(card: Card): Card {
  return {
    ...card,
    title: card.title.trim(),
    text: {
      ...card.text,
      text: card.text.text.trim(),
    },
    question: {
      ...card.question,
      options: {
        ...card.question.options,
        answers: [...card.question.options.answers.map(trimCardAnswer)],
      },
      subjects: {
        ...card.question.subjects,
        headers: [...card.question.subjects.headers.map(trimCardSubjectHeader)],
      },
    },
  }
}

export function trimCardAnswer(answer: OptionAnswer): OptionAnswer {
  return {
    ...answer,
    text: answer.text.trim(),
  }
}

export function trimCardSubjectHeader(header: Header): Header {
  return {
    ...header,
    text: header.text.trim(),
  }
}

export function isCardRouteChanged(cards: SurveyCardRecord): boolean {
  return sortCardsInRows(Object.values(cards)).some(row =>
    row.some(card => !(isEndOfRoute(card, row) || isCardRouteImmediateRight(card, row))),
  )
}

function isEndOfRoute(card: Card, row: Card[]): boolean {
  return isLastCardOnRow(card, row) && card.nextGuid === null
}

export function isCardRouteImmediateRight(card: Card, cards: Card[]): boolean {
  return card.nextGuid !== null && card.nextGuid === findFirstRight(card, cards)?.guid
}

function isLastCardOnRow(card: Card, cards: Card[]): boolean {
  return findFirstRight(card, cards) === null
}

export function getLongestAnswer(card: Card): string | undefined {
  return maxByList(answer => answer.text.length, card.question.options.answers)?.text
}

export function toErrorCopy({ type }: CardError): string {
  const ERROR_COPIES = COPY.EDITOR.ERRORS
  switch (type) {
    case 'CARD_TITLE_TOO_SHORT':
      return ERROR_COPIES[type](SETTINGS.QUESTION.MIN_TITLE_LENGTH)
    case 'CARD_TITLE_TOO_LONG':
      return ERROR_COPIES[type](SETTINGS.QUESTION.MAX_TITLE_LENGTH)
    case 'CARD_PIPING_TITLE_TOO_LONG':
      return ERROR_COPIES[type](SETTINGS.QUESTION.MAX_TITLE_LENGTH)
    case 'CARD_TEXT_TOO_LONG':
      return ERROR_COPIES[type](SETTINGS.TEXT.MAX_LENGTH)
    case 'CARD_TEXT_TOO_SHORT':
      return ERROR_COPIES[type](SETTINGS.TEXT.MIN_LENGTH)
    case 'TEXT_ANSWERS_TOO_LONG':
      return ERROR_COPIES[type](SETTINGS.QUESTION.MAX_ANSWER_LENGTH)
    case 'GRID_NOT_ENOUGH_SUBJECTS':
      return ERROR_COPIES[type](SETTINGS.QUESTION.GRID.SUBJECT_AMOUNT_MIN)
    case 'GRID_TOO_MANY_SUBJECTS':
      return ERROR_COPIES[type](SETTINGS.QUESTION.GRID.SUBJECT_AMOUNT_MAX)
    case 'GRID_TOO_MANY_SUBJECTS_IF_MASKING':
      return ERROR_COPIES[type](SETTINGS.QUESTION.GRID.SUBJECT_AMOUNT_MAX_IF_MASKING)
    case 'GRID_NOT_ENOUGH_OPTIONS':
      return ERROR_COPIES[type](SETTINGS.QUESTION.GRID.OPTION_AMOUNT_MIN)
    case 'GRID_SUBJECTS_TOO_LONG':
      return ERROR_COPIES[type](SETTINGS.QUESTION.GRID.SUBJECT_LENGTH_MAX)
    case 'GRID_OPTIONS_TOO_LONG':
      return ERROR_COPIES[type](SETTINGS.QUESTION.GRID.OPTION_LENGTH_MAX)
    case 'OPEN_TEXT_FIELD_TITLE_TOO_LONG':
      return ERROR_COPIES[type](SETTINGS.OPEN_TEXT.MAX_FIELD_TITLE_LENGTH)
    default:
      return ERROR_COPIES[type]
  }
}

export function isCardInRandomizedCardsRange(card: Card, cards: readonly Card[]): boolean {
  return getCardRandomizedRangeIndex(card, cards) !== null
}

export function getCardRandomizedRangeIndex(card: Card, cards: readonly Card[]): number | null {
  const randomizedCardIndexes = cards
    .map(({ isRandomized }, index) => (isRandomized ? index : null))
    .filter((index): index is number => typeof index === 'number')

  const firstRandomizedIndex = randomizedCardIndexes[0]
  const lastRandomizedIndex = randomizedCardIndexes[randomizedCardIndexes.length - 1]

  if (firstRandomizedIndex === undefined || lastRandomizedIndex === undefined) {
    return null
  }

  const cardIndex = cards.findIndex(({ guid }) => guid === card.guid)

  if (cardIndex < firstRandomizedIndex || cardIndex > lastRandomizedIndex) {
    return null
  }

  return cardIndex - firstRandomizedIndex
}

export function getCardGroupedIn(cards: Card[], guid: CardGuid): Card {
  const card = cards
    .filter(isGroupCard)
    .find(groupCard => groupCard.group.cards.some(groupedCard => groupedCard.guid === guid))

  if (!card) throw new Error(`Cannot find grouped card parent: ${guid}`)

  return card
}

export function getGroupedCardId(guid: CardGuid): string {
  return `grouped-card-${guid}`
}

export function getSurveyCardById(survey: Survey, guid: CardGuid): Card {
  const card = getCardByGuid(Object.values(survey.cards), guid)
  if (!card) throw new Error(`Cannot find card with guid: ${guid}`)
  return card
}

export function getCardByGuid(cards: Card[], guid: CardGuid): Card | undefined {
  return flattenCards(cards).find(card => card.guid === guid)
}

export function flattenCards(cards: Card[]): Card[] {
  return [
    ...new Set(
      cards.flatMap(card => (isGroupCard(card) ? [...flattenCards(card.group.cards), card] : card)),
    ),
  ]
}

export function getGroupedCards(cards: Card[]): Set<Card> {
  return new Set(
    cards.flatMap(card =>
      isGroupCard(card) ? [...card.group.cards, ...getGroupedCards(card.group.cards)] : [],
    ),
  )
}

export function getRootRandomizedGroupCards(cards: Card[]): Set<Card> {
  return new Set(
    cards.filter(
      ({ type, group }) => type === 'group' && group.cards.some(({ isRandomized }) => isRandomized),
    ),
  )
}

export function isGroupedIn(card: Card, cards: Card[]): boolean {
  return getGroupedCards(cards).has(card)
}

export function isChildOfRandomizedGroup(card: Card, cards: Card[]): boolean {
  return new Set(
    [...getRootRandomizedGroupCards(cards)].flatMap(childCard =>
      isGroupCard(childCard) ? childCard.group.cards : [],
    ),
  ).has(card)
}

export const CARD_QUESTION_ICONS = {
  group: groupSVG,
  text: textCardSVG,
  single_choice: singleChoiceSVG,
  multiple_choice: multipleChoiceSVG,
  ranked: rankedSVG,
  nps: npsSVG,
  grid: gridsSVG,
  open_text: freeTextSVG,
  video: videoSVG,
}

export function setQuestionType(card: Card, combinedCardType: CombinedCardTypeQuestion): Card {
  return {
    ...card,
    type: combinedCardType.type,
    question: {
      ...card.question,
      type: combinedCardType.subType,
      // eslint-disable-next-line unicorn/prefer-includes
      hasNone: SETTINGS.QUESTION_TYPES_WITH_DEFAULT_HAS_NONE.some(
        questionTypeWithDefaultHasNone =>
          combinedCardType.subType === questionTypeWithDefaultHasNone,
      ),
      hasNa: true,
      hasOther: false,
    },
  }
}

export function setTextType(card: Card, combinedCardType: CombinedCardTypeText): Card {
  return {
    ...card,
    type: combinedCardType.type,
    text: { ...card.text, type: combinedCardType.subType },
  }
}

export function updateQuestionType(card: Card, combinedCardType: CombinedCardTypeQuestion): Card {
  const updatedCard = resetOptionsforType(card, combinedCardType)
  return setQuestionType(updatedCard, combinedCardType)
}

function resetOptionsforType(card: Card, combinedCardType: CombinedCardType): Card {
  switch (combinedCardType.subType) {
    case 'open_text':
      return {
        ...card,
        question: {
          ...card.question,
          options: createEmptyCardQuestionOptions({
            answers: createEmptyAnswers(SETTINGS.MIN_NUM_OPEN_TEXT_HEADERS),
          }),
        },
      }
    case 'grid':
      return {
        ...card,
        question: {
          ...card.question,
          subjects:
            card.question.subjects.headers.length > 0
              ? card.question.subjects
              : createEmptyCardQuestionSubjects({
                  headers: createEmptyHeaders(SETTINGS.MIN_NUM_SUBJECT_HEADERS),
                }),
        },
      }
    case 'video':
      return {
        ...card,
        question: {
          ...card.question,
          options: createEmptyCardQuestionOptions({
            isRandomized: false,
            answers: createEmptyAnswers(SETTINGS.VIDEO.MIN_NUM_PROMPTS),
          }),
        },
      }
    case 'nps':
      return {
        ...card,
        question: {
          ...card.question,
          options: createEmptyCardQuestionOptions({
            isRandomized: false,
            answers: Array.from({ length: 11 }).map((_, index) =>
              createEmptyAnswer({ text: `${index}` }),
            ),
          }),
        },
      }
    case 'single_choice':
    case 'multiple_choice':
    case 'ranked':
    default:
      return card
  }
}

export function updateMessageType(card: Card): Card {
  return createEmptyTextCard({
    guid: card.guid,
    media: card.media?.type === 'image' ? card.media : null,
    position: card.position,
    text: { text: card.title, type: createCombinedCardType('text').subType },
    nextGuid: card.nextGuid,
  })
}

export function hasCompleteTranslationsForLanguage({
  card,
  countryLanguage,
  cardsReachableForAudiences,
}: {
  card: Card
  countryLanguage: { country: string; language: string; audienceIds: string[] }
  cardsReachableForAudiences: {
    [audienceId: string]: Set<string>
  }
}): boolean {
  const key = translationKey(countryLanguage)

  const cardHiddenForAllTargetingsWithCountryLanguage = countryLanguage.audienceIds.every(
    audienceId => !cardsReachableForAudiences[audienceId]?.has(card.guid),
  )

  if (cardHiddenForAllTargetingsWithCountryLanguage) {
    return true
  }

  if (isTextCard(card)) {
    return card.text.translations?.[key]?.text !== undefined
  }

  if (isCardNpsQuestion(card)) {
    return true
  }

  return (
    card.translations?.[key]?.title !== undefined &&
    hasCompleteTranslationsForQuestionSubjectsForLanguage({ card, countryLanguage }) &&
    hasCompleteTranslationsForQuestionOptionsForLanguage({ card, countryLanguage })
  )
}

function hasCompleteTranslationsForQuestionOptionsForLanguage({
  card,
  countryLanguage,
}: {
  card: Card
  countryLanguage: { country: string; language: string; audienceIds: string[] }
}): boolean {
  const key = translationKey(countryLanguage)
  return card.question.options.answers.every(answer => {
    if (answer.mask?.answerId !== undefined) {
      return true
    }

    const isOmitted = countryLanguage.audienceIds.some(id => answer.omittedFromAudiences.has(id))
    if (isOmitted) {
      return true
    }

    const answerTranslation = answer.translations?.[key]
    if (answer.text === '' && answerTranslation?.text === '') {
      return true
    }

    return answerTranslation && answerTranslation.text !== ''
  })
}

function hasCompleteTranslationsForQuestionSubjectsForLanguage({
  card,
  countryLanguage,
}: {
  card: Card
  countryLanguage: { country: string; language: string; audienceIds: string[] }
}): boolean {
  if (card.question.type !== 'grid') {
    return true
  }
  const key = translationKey(countryLanguage)
  return card.question.subjects.headers.every(header => {
    if (header.mask?.answerId !== undefined) {
      return true
    }

    const isOmitted = countryLanguage.audienceIds.some(id => header.omittedFromAudiences.has(id))
    if (isOmitted) {
      return true
    }

    const headerTranslation = header.translations?.[key]

    if (header.text === '' && headerTranslation?.text === '') {
      return true
    }

    return headerTranslation && headerTranslation?.text !== ''
  })
}
