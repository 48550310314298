export type RoundOutcome = 'COMPLETED' | 'SCREENED_OUT' | 'APPROVED'

export const ROUND_DEMOGRAPHIC_ARRAY_VALUES = [
  'pets',
  'age_gender_child',
  'parental_status_v2',
  'role_in_household',
  'nationality',
] as const
export type RoundDemographicsArrayValueKeys = (typeof ROUND_DEMOGRAPHIC_ARRAY_VALUES)[number]

export function isRoundDemographicArrayValue(
  name: string,
): name is RoundDemographicsArrayValueKeys {
  return ROUND_DEMOGRAPHIC_ARRAY_VALUES.includes(name as any)
}

export type RoundDemographics = Readonly<
  Partial<{
    age: number
    pets: string[]
    age_gender_child: string[]
    parental_status_v2: string[]
    role_in_household: string[]
    nationality: string[]
    [k: string]: string | number | string[]
  }>
>

export type RoundCard = Readonly<{
  answers: Record<string, RoundCardAnswer>
}>

export type RoundCardAnswer = Readonly<{
  text?: string
  sentiment?: string
  order?: number
  transcript?: string
  videoId?: string
}>

export type RoundSurvey = Readonly<{
  id: string
}>

export type RoundAudience = Readonly<{
  id: string
  country: string
}>

type CardId = string
export type Round = Readonly<{
  id: string
  audience: RoundAudience
  survey: RoundSurvey
  waveTimestamp: number
  outcome: RoundOutcome
  demographics: RoundDemographics
  cards: Record<CardId, RoundCard>
}>
