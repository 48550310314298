import { pick } from '@attest/util'

import {
  type Segmentations,
  toApiSegmentations,
  transformApiSegmentationsToSegmentations,
} from '../../segmentations'
import type { Quota } from '../model'

import type { ApiQuota } from './model'

export function transformApiQuotasToQuotas(quotas: ApiQuota[]): Quota[] {
  return quotas
    .map(quota => ({
      limit: quota.limit,
      segmentations: transformApiSegmentationsToSegmentations(quota.segmentations),
    }))
    .filter(quota => Object.keys(quota.segmentations).length > 0)
}

export function toApiQuotas(quotas: Quota[]): ApiQuota[] {
  return quotas
    .map(quota => ({
      limit: quota.limit,
      segmentations: toApiSegmentations(quota.segmentations),
    }))
    .filter(({ segmentations }) => Object.keys(segmentations).length > 0)
}

export function segmentationsModelFromQuotas(
  quotas: ApiQuota[],
  supportedDemographicKeys: string[],
): Segmentations {
  const quotaSegmentations = quotas
    .map(quota =>
      pick(transformApiSegmentationsToSegmentations(quota.segmentations), supportedDemographicKeys),
    )
    .filter(segmentation => Object.keys(segmentation).length > 0)

  const transformed: Segmentations = {}
  quotaSegmentations.forEach(segmentations => {
    for (const key in segmentations) {
      const segmentation = segmentations[key]
      if (segmentation === undefined) {
        continue
      }
      if (transformed[key] === undefined) {
        transformed[key] = segmentation
        continue
      }
      transformed[key] = (transformed[key] as string[]).concat(segmentations[key] as string[])
    }
  })

  return transformed
}
