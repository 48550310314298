import type { Merge } from 'ts-essentials'

import { collectBy, mergeDeepAll } from '@attest/util'

import type {
  MergedStudyQuestionStructure,
  StudyQuestionOptionResponseType,
  StudyQuestionStructure,
  StudyQuestionStructureOptionSettings,
} from './model'
import { isNonStaticOption, isStaticOption, type Option } from './option'
import { sortQuestionStructuresByAscendingPublishedTimestamp } from './sorter'
import type { Subject } from './subject'

export function createStudyQuestionStructure<O extends StudyQuestionStructureOptionSettings>(
  override: Merge<
    Partial<StudyQuestionStructure<O>>,
    {
      waveId: string
    }
  >,
): StudyQuestionStructure<O> {
  return Object.freeze({
    title: override.title ?? '',
    waveId: override.waveId,
    publishedTimestamp: override.publishedTimestamp ?? undefined,
    options: override.options ?? [],
    subjects: override.subjects ?? [],
    next: override.next ?? undefined,
    optionSettings: createStudyQuestionStructureOptionSettings(
      override.optionSettings ?? { type: 'single' },
    ) as O,
    displayLogic: override.displayLogic,
    mediaUrl: override.mediaUrl,
    omitted: override.omitted ?? false,
  })
}

export function createStudyQuestionStructureOptionSettings<
  T extends StudyQuestionOptionResponseType,
>(
  override: Merge<Partial<StudyQuestionStructureOptionSettings<T>>, { type: T }>,
): StudyQuestionStructureOptionSettings<T> {
  return Object.freeze({
    type: override.type,
    limit: override.limit,
  })
}

export function createMergedStudyQuestionStructure<S extends StudyQuestionStructure>(
  structures: S[],
): MergedStudyQuestionStructure<S> {
  const sortedStructures = sortQuestionStructuresByAscendingPublishedTimestamp(structures)
  return Object.freeze({
    waveIds: [...sortedStructures].map(({ waveId }) => waveId),
    next: sortedStructures.at(-1)?.next ?? undefined,
    optionSettings: createMergedOptionSettings(
      sortedStructures.map(structure => structure.optionSettings),
    ),
    displayLogic: sortedStructures.at(-1)?.displayLogic ?? undefined,
    options: createMergedOptions(sortedStructures.flatMap(structure => structure.options)),
    subjects: createMergedSubjects(sortedStructures.flatMap(structure => structure.subjects)),
  }) as MergedStudyQuestionStructure<S>
}

function createMergedOptionSettings(
  optionSettings: StudyQuestionStructureOptionSettings[],
): Readonly<StudyQuestionStructureOptionSettings> {
  return Object.freeze(
    mergeDeepAll([
      createStudyQuestionStructureOptionSettings({ type: 'single' }),
      ...optionSettings,
    ]),
  )
}

function createMergedOptions(options: Option[]): readonly Option[] {
  const allMerged = collectBy(options, option => option.id).map(createMergedOption)

  return Object.freeze([
    ...allMerged.filter(isNonStaticOption),
    ...allMerged.filter(isStaticOption),
  ])
}

function createMergedOption(options: Option[]): Readonly<Option> {
  return Object.freeze({
    ...mergeDeepAll(options),
    isQualifying: options.some(option => option.isQualifying),
    isRandomized: options.some(option => option.isRandomized),
    answerIds: Object.freeze([...new Set(options.flatMap(option => option.answerIds))]),
  })
}

function createMergedSubjects(subjects: Subject[]): readonly Subject[] {
  return Object.freeze(collectBy(subjects, subject => subject.id).map(createMergedSubject))
}

function createMergedSubject(subjects: Subject[]): Readonly<Subject> {
  return {
    ...mergeDeepAll(subjects),
    isRandomized: subjects.some(subject => subject.isRandomized),
    answerIds: Object.freeze([...new Set(subjects.flatMap(subject => subject.answerIds))]),
  }
}
