import type { AxiosError } from 'axios'
import { defineStore } from 'pinia'

import { type ApiError, type InternalError, isAppError } from '@attest/_api'
import { useCardResponseFiltersStore } from '@attest/results-saved-filters'
import { useStudyStore } from '@attest/study'
import type { SurveyGuid } from '@attest/survey'
import { isNotNil, mergeDeepWith, omit } from '@attest/util'
import { createExpectedError, createExpectedResolved, type Expected, union } from '@attest/util'

import {
  type ApiStudyResultsVideoResponsesData,
  approveTakers,
  type EmptyStudyResultsCard,
  getStudyResultsByWave,
  getVideoResponses,
  isApprovedRespondent,
  isCompletedRespondent,
  rejectTakers,
  type RejectTakersReason,
  type StudyResultsCardId,
  type StudyResultsRespondent,
  type StudyResultsRespondentId,
  type StudyResultsResponseData,
  type StudyResultsVideoResponsesData,
} from '..'

export type StudyResultsState = {
  latestId: null | string | SurveyGuid
  cardIdToCard: Record<StudyResultsCardId, EmptyStudyResultsCard>
  respondentIdToRespondent: Record<StudyResultsRespondentId, StudyResultsRespondent>
  videoIdToVideoResponse?: StudyResultsVideoResponsesData
  loadedWaves: string[]
}

export function defaultResultsState(): StudyResultsState {
  return {
    latestId: null,
    loadedWaves: [],
    cardIdToCard: {},
    respondentIdToRespondent: {},
    videoIdToVideoResponse: {},
  }
}

export const quickSelectAgeBands = [
  { min: 18, max: 24 },
  { min: 25, max: 34 },
  { min: 35, max: 44 },
  { min: 45, max: 54 },
  { min: 55, max: 64 },
  { min: 65, max: 100, name: '65+' },
]

export const STUDY_RESULTS_NAMESPACE = 'studyResults'

export const useStudyResultsStore = defineStore(STUDY_RESULTS_NAMESPACE, {
  state: defaultResultsState,
  getters: {
    respondentIds(): Set<StudyResultsRespondentId> {
      return new Set(Object.keys(this.respondentIdToRespondent))
    },

    respondents(): Set<StudyResultsRespondent> {
      return new Set(Object.values(this.respondentIdToRespondent))
    },

    completedRespondentIds(): Set<string> {
      return new Set(
        Object.values(this.respondentIdToRespondent)
          .filter(isCompletedRespondent)
          .map(({ id }) => id),
      )
    },

    approvedRespondentIds(): Set<string> {
      return new Set(
        Object.values(this.respondentIdToRespondent)
          .filter(isApprovedRespondent)
          .map(({ id }) => id),
      )
    },

    videoResponses(): StudyResultsVideoResponsesData | undefined {
      return this.videoIdToVideoResponse
    },
  },
  actions: {
    async approveTakers(payload: { round_guids: string[] }): Promise<undefined | Error> {
      return approveTakers(payload)
    },

    async rejectTakers(payload: {
      guid: string
      reason: RejectTakersReason
      cardIds: string[]
      responseIds: string[]
      waveSurveyId: string
    }): Promise<InternalError<ApiError> | void> {
      const result = rejectTakers(payload)
      if (isAppError(result)) return result
      this.respondentIdToRespondent = Object.freeze(
        omit({ ...this.respondentIdToRespondent }, payload.responseIds),
      )
      useCardResponseFiltersStore().deleteAllFilters()
    },

    async getAllStudyResults(): Expected<void, { id: string; error: Error | AxiosError }[]> {
      const { study, waveSurveyIdToWaveSurvey } = useStudyStore()
      if (!study) throw new Error('study expected')

      return this.getStudyResultsByWave({ waveSurveyIds: Object.keys(waveSurveyIdToWaveSurvey) })
    },

    async getStudyResultsByWave(payload: {
      waveSurveyIds: string[]
    }): Expected<void, { id: string; error: Error | AxiosError }[]> {
      const { study } = useStudyStore()
      if (!study) throw new Error('study expected')
      if (study.id !== this.latestId) {
        this.$reset()
        this.latestId = study.id
      }

      const unloadedWaves = payload.waveSurveyIds.filter(id => !this.loadedWaves.includes(id))
      if (unloadedWaves.length === 0) return createExpectedResolved()

      const results = await Promise.all(
        unloadedWaves.map(waveSurveyId =>
          getStudyResultsByWave({ id: study.id, waveSurveyIds: [waveSurveyId] }),
        ),
      )

      for (const [result, error] of results) {
        if (error) continue
        this.updateStoreFromResults(result)
      }

      const unavailableWaveIds = results
        .map(([_, error], index) => (error ? { id: unloadedWaves[index], error } : undefined))
        .filter(isNotNil)
      const fetchedWaveIds = results
        .map(([_, error], index) => (error ? undefined : unloadedWaves[index]))
        .filter(isNotNil)

      this.loadedWaves = [...this.loadedWaves, ...fetchedWaveIds]

      return unavailableWaveIds.length === 0
        ? createExpectedResolved()
        : createExpectedError(unavailableWaveIds)
    },

    async getVideoResponses(payload: {
      videoIds: string[]
    }): Promise<ApiStudyResultsVideoResponsesData['video_content'] | InternalError<ApiError>> {
      const data = await getVideoResponses({ videoIds: payload.videoIds })
      if (isAppError(data)) return data

      this.videoIdToVideoResponse = data

      return data
    },

    async patchVideoResponses(payload: {
      videoIds: string[]
    }): Promise<ApiStudyResultsVideoResponsesData['video_content'] | InternalError<ApiError>> {
      const data = await getVideoResponses({ videoIds: payload.videoIds })
      if (isAppError(data)) return data

      this.videoIdToVideoResponse = { ...this.videoIdToVideoResponse, ...data }

      return this.videoIdToVideoResponse
    },

    async updateStoreFromResults(
      data: StudyResultsResponseData,
    ): Promise<StudyResultsResponseData | InternalError<ApiError>> {
      this.respondentIdToRespondent = Object.freeze({
        ...this.respondentIdToRespondent,
        ...Object.fromEntries(
          Object.entries(data.respondentIdToRespondent).map(([id, respondent]) => {
            const { age } = respondent.nameToSegmentation
            const band = quickSelectAgeBands.find(({ min, max }) => {
              return typeof age === 'number' && age >= min && age <= max
            })

            return [
              id,
              {
                ...respondent,
                nameToSegmentation: {
                  ...respondent.nameToSegmentation,
                  ...(band ? { nat_rep_age: band.name ?? `${band.min} - ${band.max}` } : {}),
                },
              },
            ]
          }),
        ),
      })

      this.cardIdToCard = Object.freeze(
        mergeDeepWith(this.cardIdToCard, data.cardIdToCard, (x, y) => {
          if (x instanceof Set && y instanceof Set) return union([x, y])
          return
        }),
      )

      return data
    },
  },
})
