import { type FilterBlock, type FilterExpression, Logic, Operator } from '@attest/efl-antlr4'
import { never } from '@attest/util'

import {
  ArrayOperator,
  type FilterNode,
  isFilterBlock,
  isFilterExpression,
  type PrimitiveValue,
  type Value,
} from './model'

export type GetField<T> = (data: T, key: string) => Value

export function filter<T = Value>(
  data: Set<T>,
  filterNode: FilterNode,
  getField?: GetField<T>,
): Set<T> {
  return new Set([...data].filter(item => filterWithNode(item, filterNode, getField)))
}

function filterWithNode<T>(item: T, node: FilterNode, getField?: GetField<T>): boolean {
  if (isFilterBlock(node)) {
    return filterWithBlock(item, node, getField)
  }

  if (isFilterExpression(node)) {
    return filterWithExpression(item, node, getField)
  }

  return false
}

function filterWithBlock<T>(item: T, filterBlock: FilterBlock, getField?: GetField<T>): boolean {
  switch (filterBlock.logic) {
    case Logic.OR:
      return filterBlock.items.some(node => filterWithNode(item, node, getField))
    case Logic.AND:
      return filterBlock.items.every(node => filterWithNode(item, node, getField))
    case Logic.NONE:
      return filterWithNode(item, filterBlock.items[0], getField)
    default:
      throw never(filterBlock.logic)
  }
}

function filterWithExpression<T>(
  item: T,
  filterExpression: FilterExpression,
  getField?: GetField<T>,
): boolean {
  const comparison = compare(
    filterExpression.operator,
    getField?.(item, filterExpression.key) ?? null,
    filterExpression.keyArray,
    filterExpression.value,
    filterExpression.valueArray,
    filterExpression.strict,
  )
  return filterExpression.not ? !comparison : comparison
}

function compare(
  operator: Operator,
  field: Value,
  keyArrayOperator: ArrayOperator,
  value: Value,
  valueArrayOperator: ArrayOperator,
  strict: boolean,
): boolean {
  if (Array.isArray(field)) {
    if (keyArrayOperator === ArrayOperator.ALL) {
      return field.every(a =>
        compare(operator, a, ArrayOperator.NONE, value, valueArrayOperator, strict),
      )
    }

    if (keyArrayOperator === ArrayOperator.ANY) {
      return field.some(a =>
        compare(operator, a, ArrayOperator.NONE, value, valueArrayOperator, strict),
      )
    }

    return false
  }

  if (Array.isArray(value)) {
    if (valueArrayOperator === ArrayOperator.ALL) {
      return value.every(b =>
        compare(operator, field, keyArrayOperator, b, ArrayOperator.NONE, strict),
      )
    }

    if (valueArrayOperator === ArrayOperator.ANY) {
      return value.some(b =>
        compare(operator, field, keyArrayOperator, b, ArrayOperator.NONE, strict),
      )
    }

    return false
  }

  switch (operator) {
    case Operator.EQ:
      return field === value
    case Operator.GE:
      return compareNumber(field, value, (a, b) => a >= b)
    case Operator.GT:
      return compareNumber(field, value, (a, b) => a > b)
    case Operator.LE:
      return compareNumber(field, value, (a, b) => a <= b)
    case Operator.LT:
      return compareNumber(field, value, (a, b) => a < b)
    case Operator.ENDSWITH:
      return strict
        ? compareString(field, value, (a, b) => a.endsWith(b))
        : compareString(field, value, (a, b) =>
            a.toLocaleLowerCase().endsWith(b.toLocaleLowerCase()),
          )
    case Operator.STARTSWITH:
      return strict
        ? compareString(field, value, (a, b) => a.startsWith(b))
        : compareString(field, value, (a, b) =>
            a.toLocaleLowerCase().startsWith(b.toLocaleLowerCase()),
          )
    case Operator.CONTAINS:
      return strict
        ? compareString(field, value, (a, b) => a.includes(b))
        : compareString(field, value, (a, b) =>
            a.toLocaleLowerCase().includes(b.toLocaleLowerCase()),
          )
    default:
      throw never(operator)
  }
}

function compareNumber(
  key: PrimitiveValue,
  value: PrimitiveValue,
  predicate: (key: number, value: number) => boolean,
): boolean {
  return predicate((key ?? 0) as number, (value ?? 0) as number)
}

function compareString(
  key: PrimitiveValue,
  value: PrimitiveValue,
  predicate: (key: string, value: string) => boolean,
): boolean {
  return predicate((key ?? '') as string, (value ?? '') as string)
}
