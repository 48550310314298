import EflService, { FilterBlock, type FilterExpression } from '@attest/efl-antlr4'
import { getVariation } from '@attest/feature-switch'

import { createFilterExpression, NONE } from './factory'
import { type FilterNode, isFilterBlock, isFilterExpression } from './model'

export function eflStringToFilterNode(query: string): FilterNode {
  return transformV1FilterNodeToV2(EflService.getFilterFromQueryString(fixNotBetween(query)))
}

function fixNotBetween(v1: string): string {
  return v1.replaceAll(
    /([^\s(]+) not between (\d+) \.\. (\d+)/gi,
    (_, a, b, c) => `${a} NOT >= ${b} OR ${a} NOT <= ${c}`,
  )
}

function transformV1FilterNodeToV2(filterNode: FilterNode): FilterNode {
  if (isFilterBlock(filterNode)) {
    return new FilterBlock(
      filterNode.items.map(item => transformV1FilterNodeToV2(item)),
      filterNode.logic,
    )
  }

  if (isFilterExpression(filterNode)) {
    return transformV1FilterExpressionToV2(filterNode)
  }

  return filterNode
}

function transformV1FilterExpressionToV2(filterExpression: FilterExpression): FilterExpression {
  return createFilterExpression(
    transformV1FilterExpressionKeyToV2(filterExpression.key),
    filterExpression.operator,
    filterExpression.value,
    { not: filterExpression.not, strict: filterExpression.strict },
  )
}

function transformV1FilterExpressionKeyToV2(key: string): string {
  switch (key) {
    case 'wave_published_time':
    case 'waveTimestamp':
      return 'waveTimestamp'
    case 'audience':
    case 'audience.id':
      return 'audience.id'
    case 'country':
    case 'audience.country':
      return 'audience.country'
    default: {
      const { groups: { cardId = undefined, field = undefined } = {} } =
        key.match(/^answers\.(?<cardId>[^.]+)\.?(?<field>[^.]+)$/) ??
        key.match(/^cards\.(?<cardId>[^.]+)\.answers\..+?\.?(?<field>[^.]+)$/) ??
        {}
      if (cardId && field) {
        return `cards.${cardId}.answers.*.${field}`
      }

      const name = key.replace('demographics.', '')

      if (
        [
          'pets',
          'age_gender_child',
          'parental_status_v2',
          'role_in_household',
          'nationality',
        ].includes(name)
      ) {
        return `demographics.${name}.*`
      }

      return `demographics.${name}`
    }
  }
}

export function filterNodeToEFLString(node: FilterNode, convertToV1 = false): string | undefined {
  if (isFilterBlock(node)) {
    return convertEFLStringVersion(EflService.getQueryStringFromFilterBlock(node), convertToV1)
  }

  if (isFilterExpression(node)) {
    return convertEFLStringVersion(
      EflService.getQueryStringFromFilterBlock(NONE(node)),
      convertToV1,
    )
  }

  return undefined
}

function convertEFLStringVersion(
  base: string | undefined,
  convertToV1: boolean,
): string | undefined {
  if (base === undefined) {
    return base
  }

  if (getVariation('efl-v2')) {
    return base
  }

  if (!convertToV1) {
    return base
  }

  return base
    .replaceAll(
      /((?:\.\*)?\S+(?:\.\*)?) any (not )?is (.+?)/gi,
      (_, a, b, c) => `${a} ${b ?? ''}INCLUDES ${c}`,
    )
    .replaceAll(/ any (not )?is any (\[.+?])/gi, (_, a, b) => ` ${a ?? ''}IN ${b}`)
    .replaceAll(/ (not )?is any (\[.+?])/gi, (_, a, b) => ` ${a ?? ''}IN ${b}`)
    .replaceAll(/ any (not )?is (\[.+?])/gi, (_, a, b) => ` ${a ?? ''}IN ${b}`)
    .replaceAll(/ any (not )?is (.+?)/gi, (_, a, b) => ` ${a ?? ''}IS ${b}`)
    .replaceAll(/ any (not )?contains (.+?)/gi, (_, a, b) => ` ${a ?? ''}CONTAINS ${b}`)
    .replaceAll(
      /(.+)? >= (\d+) and .+? <= (\d+?)/gi,
      (_, a, min, max) => `${a} BETWEEN ${min} .. ${max}`,
    )
    .replaceAll(
      /(.+)? not >= (\d+) or .+? not <= (\d+)/gi,
      (_, a, min, max) => `${a} NOT BETWEEN ${min} .. ${max}`,
    )
    .replaceAll(/demographics\.(.+?)\.\* /gi, (_, a) => `${a} `)
    .replaceAll(/demographics\.(.+?) /gi, (_, a) => `${a} `)
    .replaceAll(/wavetimestamp /gi, 'wave_published_time ')
    .replaceAll(/audience\.id /gi, 'audience ')
    .replaceAll(/audience\.country /gi, 'country ')
    .replaceAll(/cards\.(.+?)\.answers\..+?\.(id|text) /gi, (_, a, b) => `answers.${a}.${b} `)
    .replaceAll(/ strict ([A-z]+) /gi, (_, a) => ` STRICT${a} `)
    .replaceAll(/ ([A-z]+) strict /gi, (_, a) => ` STRICT${a} `)
}
